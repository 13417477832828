import { MouseEvent, useState } from "react";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import styles from "../../styles/Components.module.css";
import BaseModal from './BaseModal';
import BaseText from './BaseText';
import SvgComponent from "./SvgComponent";

interface ShareInterface {
    isOpen: boolean;
    title: string;
    link: string
    setShowShareModal:() => void;
    linkTitle: string
    linkSummary: string
    hashtags?: string
}

export default function ShareFeature({title, hashtags, isOpen, linkTitle, linkSummary, link, setShowShareModal}:ShareInterface) {

    const [isCopied, setIsCopied] = useState(false)

    async function copyTextToClipboard(text: string) {
        if ("clipboard" in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          try {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
          } catch (error) {
            console.error("Unable to copy to clipboard:", error);
          }
        }
      }
    
      const handleButtonClick = (e:MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        copyTextToClipboard(link);
        setIsCopied(true);
      };

  return (
    <BaseModal
    isOpen={isOpen}
    onClose={() => setShowShareModal()}
    boxWidth="max-w-lg"
  >
    <div className="flex relative flex-col text-center px-8 py-12 justify-center items-center gap-4">
      <button
        className="absolute border-0 right-3 md:right-0 top-0 outline-none"
        onClick={() => setShowShareModal()}
      >
        <SvgComponent icon="cancel" />
      </button>

      <BaseText size="x-large" weight="bold">
        { title }
      </BaseText>
      <label
        htmlFor="linkField"
        className="flex gap-2 items-center md:flex-nowrap flex-wrap"
      >
        <input
          disabled
          className={`${styles.formInput}`}
          value={link}
          type="text"
        />
        <button
          onClick={(e) => handleButtonClick(e)}
          className="inline-flex items-center px-5 h-[45px] border-r-top text-sm rounded-lg transition ease-out hover:bg-sky-600 active:shadow-lg bg-sky-400 opacity-70 "
        >
          {`${isCopied ? 'COPIED!' : 'COPY'}`}
          <span className="pl-2">
            
            <SvgComponent icon="copy" />
          </span>
        </button>
      </label>

      <ul className="flex justify-start md:justify-center w-full mt-2 md:mt-0 gap-4">
        <li className="hover:scale-110 transition ease-linear">
          <FacebookShareButton
            url={link}
            hashtag={hashtags || ''}
          >
            <FacebookIcon size={38} borderRadius={10}/>
          </FacebookShareButton>
        </li>
        <li className="hover:scale-110 ">
          <LinkedinShareButton
            title={linkTitle}
            summary={linkSummary}
            url={link}
          >
            <LinkedinIcon size={38} borderRadius={10} />
          </LinkedinShareButton>
        </li>
        <li className="hover:scale-110 ">
          <TwitterShareButton hashtags={[hashtags||'']} title={linkTitle} url={link}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="41px" height="41px"><path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"/></svg>
          </TwitterShareButton>
        </li>
        <li className="hover:scale-110 ">
          <WhatsappShareButton separator="|" title={linkTitle} url={link}>
            <WhatsappIcon size={38} borderRadius={10}/>
          </WhatsappShareButton>
        </li>
      </ul>
    </div>
  </BaseModal>
  )
}
