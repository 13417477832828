import { userProfile } from '../store/mainReducer';
import { useAppSelector } from './storeHook';

export default function useProfileData() {
  const userProfileData = useAppSelector(userProfile);

  if (userProfileData) {
    const {
      firstName,
      lastName,
      jobTitle,
      profileImageUrl,
      userId,
      user,
      verifiedSkills,
      country,
      partners,
      partnerIds,
      softSkills,
      workExperiences,
      achievements,
      education,
      coins,
      completedChallenges,
      activeChallenges,
      about,
      skills,
      areasOfInterest,
      notificationSettings,
      showTourGuide,
      showTalentWorkHistory,
      showTalentChallengeHistory,
    } = userProfileData;

    return {
      firstName,
      lastName,
      jobTitle,
      partnerIds,
      verifiedSkills,
      userId,
      skills,
      softSkills,
      workExperiences,
      activeChallenges,
      coins,
      achievements,
      completedChallenges,
      education,
      partners,
      profileImageUrl,
      areasOfInterest,
      user,
      country,
      about,
      notificationSettings,
      showTourGuide,
      showTalentWorkHistory,
      showTalentChallengeHistory,
    };
  } else {
    // Retrieve data from localStorage if userProfile is not populated
    const talenvoUserProfile = localStorage.getItem('talenvoUserProfile');
    if (talenvoUserProfile) {
      return JSON.parse(talenvoUserProfile);
    } else {
      return {};
    }
  }
}
