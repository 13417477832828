import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { alertSchema, userProfileSchema } from '../types';

// THIS HOLDS AUTH INFO, DASHBOARD INFO, LOADER AND ALERT COMPONENT INFO
export interface MainStoreState {
  loadingState: boolean;
  alert: alertSchema
  showAlert: boolean,
  jwtToken: string | null,
  userProfile: userProfileSchema | null,
}

const initialState: MainStoreState = {
  loadingState: false,
  alert: {
    type: 'info',
    messageBody: 'Please select',
    messageTitle: 'Please select the sample'
  },
  userProfile: null,
  showAlert: false,
  jwtToken: '',
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    hideAlert: (state) => {
      state.showAlert = false;
    },
    updateUserProfile: (state, action) => {
        if (state.userProfile === null) {
          state.userProfile = action.payload;
        } else {
          state.userProfile = { ...state.userProfile, ...action.payload}
        }
      },
    toggleLoader: (state, action: PayloadAction<boolean>) => {
      state.loadingState = action.payload
    },
    setAlert: (state, action: PayloadAction<MainStoreState['alert']>) => {
      state.alert = action.payload;
      state.showAlert = true;
    },
    updateTokenStatus: (state, action) => {
      state.jwtToken = action.payload;
      if (action.payload) {
        localStorage.setItem('talenvoJwToken', action.payload);
      } else {
        localStorage.removeItem('talenvoJwToken')
        localStorage.removeItem('talenvoUserProfile')
      }
    }
  },
});

export const { toggleLoader, hideAlert, updateTokenStatus, updateUserProfile, setAlert } = mainSlice.actions;


export const showingAlert = (state: RootState) => (state.main as any).showAlert as boolean;
export const alertData = (state: RootState) => (state.main as any).alert;
export const userToken = (state: RootState) => (state.main as any).jwtToken;
export const loadingState = (state: RootState) => (state.main as any).loadingState as boolean;
export const userProfile = (state: RootState) => (state.main as any).userProfile as userProfileSchema;

export default mainSlice.reducer;
