import React, { useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface BaseModalProps {
  isOpen: boolean;
  boxWidth: 'max-w-xl' | 'max-w-l' | 'max-w-3xl' | 'max-w-2xl' | 'max-w-sm' | 'max-w-md' | 'max-w-lg';
  onClose: () => void;
  padded?: boolean
  easilyDismissed?: boolean;
  children: ReactNode;
}

const BaseModal: React.FC<BaseModalProps> = ({ easilyDismissed=true, isOpen, padded=true, onClose, children, boxWidth }) => {
  
  useEffect(() => {
    if (isOpen && easilyDismissed) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (!(event.target as HTMLElement).closest("#modal-content")) {
          onClose();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [easilyDismissed, isOpen, onClose]);

  return (
    <>
    { isOpen && createPortal(  <div className="fixed inset-0 flex items-center z-50 justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div
            id="modal-content"
            className={`z-40 bg-white ${padded ? 'p-6' : 'p-0'} rounded-lg shadow-lg ${boxWidth}`}
          >
            {children}
          </div>
        </div>, document.body)}
      

    </>
  );
};

export default BaseModal;
