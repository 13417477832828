import React from 'react';
import SvgComponent from '../Generics/SvgComponent';
import useProfileData from '../../hooks/UserProfileData';
import Avatar from '../../resources/avatar.png';
import { apiOptions } from '../../types';

interface ProfileCardProps {
  onClose: () => void;
}
const ProfileCard = ({ onClose }: ProfileCardProps) => {
  const { firstName, lastName, jobTitle, profileImageUrl, skills, about } =
    useProfileData();
  return (
    <div
      className={`fixed inset-0 flex items-center m-[10px] lg:m-0 justify-center z-50`}
    >
      <div className='fixed inset-0 bg-black opacity-50'></div>
      <div className='relative bg-white rounded-lg w-[650px] flex flex-col gap-6'>
        <div className='bg-[#043299] rounded-lg py-6'>
          <div className='flex items-center justify-center flex-col gap-4'>
            <SvgComponent
              className='ml-auto px-6'
              icon='close-icon'
              onClick={onClose}
            />
            <div>
              <img
                src={profileImageUrl ? profileImageUrl : Avatar}
                alt='ProfilePic'
                srcSet=''
                className='w-[150px] h-[150px] rounded-full  border-white border-[5px]'
              />
            </div>
          </div>
        </div>

        <div className='flex items-center justify-center flex-col gap-4 '>
          <h1 className='text-[23px] font-bold'>
            {firstName} {lastName}
          </h1>
          <p className='text-[16px] px-2 lg:px-0 text-center lg:w-[65%]'>
            {jobTitle?.name}
          </p>
          <p className='text-[16px] text-[#333333] px-2 lg:px-0 text-center lg:w-[65%]'>
            {about}
          </p>
        </div>
        <hr className='h-[1.5px] ' />

        <div className='text-center pb-6'>
          <h1 className='text-[18px] text-[#333333] font-medium'>Skill(s)</h1>
          {skills.map((skill: apiOptions) => skill.name).join(', ')}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
