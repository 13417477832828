// import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppLogo from '../../resources/logo.svg';
import BaseButton from './BaseButton';
import ShareFeature from './ShareFeature';
import { useState } from 'react';
import useProfileData from '../../hooks/UserProfileData';
import { userProfileSchema } from '../../types';
import mixin from '../../utils/helperFunction';
// import NotificationItem from '../notifications/NotificationItem';
// import SvgComponent from './SvgComponent';

interface NavbarProps {
  onToggleSidebar?: () => void;
  isSidebarVisible: boolean;
  inApp?: boolean;
}

const NavigationBar = ({
  onToggleSidebar,
  inApp,
  isSidebarVisible,
}: NavbarProps) => {
  const [openShare, setOpenShare] = useState(false);
  const { partners } = useProfileData() as userProfileSchema;

  const computedAppUrl = mixin.getHost().includes('staging') ? 'https://talents-test.onrender.com' : 'https://app.talenvo.co';
  // const computedName = () => `${firstName || 'Hello'} ${lastName || ''}`;
  // const computedRole = () => (jobTitle ? `${jobTitle.name}` : '');
  // const computedProfileURL = () =>
  //   profileImageUrl ||
  //   'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png';

  return (
    <div
      className={` bg-white ${inApp ? ' border border-bottom-gray-100 ' : ''} `}
    >
      <div
        className={`flex justify-between items-center ${
          inApp ? 'py-2' : 'py-4'
        } px-4`}
      >
{partners && partners.length > 0 && <ShareFeature linkTitle={partners[0].name} setShowShareModal={() => setOpenShare(false)} link={`${computedAppUrl}/auth/signup?community=${partners[0].id}`} isOpen={openShare} title={"Share your community's unique sign up link"} linkSummary={` Sign up using ${partners[0].name}'s link to become a member of their community on Talenvo `} />
}        <Link to='/'>
          {' '}
          <img src={AppLogo} alt='Talenvo Logo' />
        </Link>

        {inApp && (
          <div className='items-center relative hidden lg:flex'>
            {/* Notification */}
            {/* <div
              className='mr-20 bg-[#CBD4E9]  p-4 px-5 rounded-xl cursor-pointer'
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className='relative'>
                <SvgComponent icon='notification' />
                <div className='absolute -right-2 -top-2'>
                  <span className='text-xs font-bold px-[6px] py-[3px] text-white bg-red-500 rounded-full'>
                    0
                  </span>
                </div>
              </div>
            </div> */}
            {/* {menuOpen && (
              <div
                className='h-screen overflow-scroll absolute
          top-[3rem] right-0 p-4 scrollbar-hide z-30'
              >
                <NotificationItem />
              </div>
            )} */}
            {/* Notification ends here */}

            {/* <div className='relative'>
            <DisplayPicture
              altText='Nav DP'
              imageSource={computedProfileURL()}
            />
            <div className='bg-green-400 text-green-400 absolute w-2 h-2 rounded-full bottom-1 right-0'></div>
          </div> */}

       {partners && partners.length > 0 && <div className='flex flex-col ml-4 mr-8 '>
           <BaseButton taller handleEngagement={() => setOpenShare(true)} mode='outline'>
           <div className='flex items-center'>
           Community Link
            </div></BaseButton>
          </div>}
          </div>
        )}
        {inApp && (
          <div className='items-center relative lg:hidden flex ease-in-out transition-all duration-300'>
            <button
              onClick={() => {
                if (onToggleSidebar) onToggleSidebar();
              }}
              className='outline-0 focus:outline-none bg-[#CBD4E9] relative p-2 rounded-xl cursor-pointer'
            >
              {/* {isSidebarVisible ? (
              <SvgComponent cancelIconLarger icon="cancel" />
            ) : (
              <SvgComponent icon="hamburger-icon" />
            )} */}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
