import { useEffect, useState } from 'react';
import { NavigateFunction, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/storeHook';
import { loadingState } from '../store/mainReducer';
import Sidebar from '../components/Generics/Sidebar';
import ThreeDLoader from '../components/Generics/threeDLoader/ThreeDLoader';
import NavigationBar from '../components/Generics/TopNavigation';
import { showingAlert } from '../store/mainReducer';
import BaseAlert from '../components/Generics/BaseAlert';

function DashboardLayout() {
  const showAlert = useAppSelector(showingAlert);
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const isLoading = useAppSelector(loadingState);

  const currentPath = location.pathname;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const navigate: NavigateFunction = useNavigate();

  const isAuthenticated = localStorage.getItem('talenvoJwToken');

  // Update sidebar state on window resize
  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
      if (currentPath === "/" && isAuthenticated) {
        navigate("/dashboard");
      }
    
  }, [isAuthenticated, navigate, currentPath])

  return (
    <div className='flex h-screen bg-white'>
      {/* Sidebar */}
      <Sidebar hideSidebar={() => toggleSidebar()} isOpen={isSidebarOpen} />

      {/* Main Content */}
      <div className='flex flex-col flex-1 overflow-hidden'>
        {/* Navbar */}
        {isLoading && <ThreeDLoader active={isLoading} />}
        <NavigationBar
          inApp
          isSidebarVisible={isSidebarOpen}
          onToggleSidebar={toggleSidebar}
        />
        {showAlert && <BaseAlert />}
        <div
          className={`${
            isSidebarOpen
              ? ' overflow-hidden md:overflow-auto ml-auto lg:ml-64 p-4 '
              : ' sm:p-8 p-4'
          } relative no-scrollbar overflow-y-auto bg-[#F7F7F7] h-screen`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
