import { useEffect, useState } from 'react';
import NavigationBar from '../components/Generics/TopNavigation';
import onboardingBg from '../resources/Sideview.png';
import onboardingBg2 from '../resources/Sideview2.png';
import { Outlet } from 'react-router-dom';
import { showingAlert } from '../store/mainReducer';
import BaseAlert from '../components/Generics/BaseAlert';
import { useAppSelector } from '../hooks/storeHook';

export default function OnboardingLayout() {
  const showAlert = useAppSelector(showingAlert);

  const [bgImage, setBgImage] = useState(onboardingBg);

  useEffect(() => {
    const getRandomNumberAndCheckParity = () => {
      const randomNumber = Math.floor(Math.random() * 10) + 1;
      if (randomNumber % 2 === 0) {
        setBgImage(onboardingBg2);
      } else {
        setBgImage(onboardingBg);
      }
    };

    getRandomNumberAndCheckParity();
  }, []);

  return (
    <div className='flex w-full max-w-screen-2xl 2xl:mx-auto relative overflow-hidden'>
      <div className='w-screen fixed top-0 z-10'>
        <NavigationBar isSidebarVisible={false} />
      </div>
      <div className=' w-1/2 hidden md:block max-h-screen relative '>
        <img
          src={bgImage}
          alt='Background'
          className='w-full object-cover h-auto'
        />
        <div
          className={`absolute w-full bg-[#0433996e] flex items-end justify-center pb-14 h-screen left-0 top-0 `}
        >
          <div className='flex flex-col w-8/12 items-start gap-4 pb-10'>
            <h1 className=' opacity-100 text-white font-bold text-5xl '>
            Turn Passion into Progress
            </h1>
            <p className='text-white text-lg '>
            Empower your community with real-world projects that inspire growth
            </p>
          </div>
        </div>
      </div>
      {showAlert && <BaseAlert />}
      <div className='md:w-1/2 lg:w-1/2 flex flex-col mt-12 w-full md:mx-auto max-h-screen overflow-y-auto'>
        <Outlet />
      </div>
    </div>
  );
}
