import "../../styles/App.css";
import { MouseEvent } from "react";
import { IconStates } from "../../types/types";
import SvgComponent from "./SvgComponent";
import BaseText from "./BaseText";

interface SideNavProps {
  handleBtnClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
  isDisabled: boolean
  title: string;
  icon: IconStates;
  minimized?: boolean;
  buttonId: string;
}

const SideNavItem = ({
  handleBtnClick,
  isActive,
  title,
  icon,
  buttonId,
  isDisabled,
  minimized = false,
}: SideNavProps) => {
  const activeIcon: IconStates = isActive ? `${icon}` : icon;

  return (
    <>
      <button
        id={`button-${buttonId}`}
        onClick={(e) => handleBtnClick(e)}
        className={`${isDisabled ? 'hidden' : 'flex' } items-center justify-start p-3 sidebarItem ${
          isActive ? "active" : ""
        }`}
      >
        <div className="flex items-center  gap-4">
          <>
            <SvgComponent icon={activeIcon} iconActive={isActive} />
          </>
          <>
            <BaseText
              color={isActive ? "blue" : "black"}
              weight={isActive ? "semibold" : "medium"}
              size="small"
            >
              {title}
            </BaseText>
          </>
        </div>
      </button>
    </>
  );
};

export default SideNavItem;
